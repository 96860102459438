module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"xbird","defaultLang":"en-gb","path":"/preview","previews":true,"omitPrismicScript":true,"pages":[{"type":"Case_study","match":"/case-study/:uid","path":"/case-study","component":"/opt/build/repo/src/templates/caseStudy.js"},{"type":"Generic","match":"/internal-preview/:uid","path":"/internal-preview","component":"/opt/build/repo/src/templates/generic.js"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"xbird | Harnessing the power of AI to improve millions of lives","short_name":"xbird","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"71e9238720881afa82cbfeb93d725c5c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-66863400-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#000"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
