export const initialState = {
  currentHomeFeature: null,
  isMenuOpen: false,
  isLogoDark: false,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'revealHomeFeature':
      return {
        ...state,
        ...action.updates,
      }
    case 'toggleMenu':
      return {
        ...state,
        ...action.updates,
      }
    case 'changeLogoColor':
      return {
        ...state,
        ...action.updates,
      }

    default:
      return state
  }
}
