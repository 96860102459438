const toREM = num => num / 16
const colors = {
  primary: '#1700DE',
  // secondary: '#f00',
  navy: '#15333D',
  gray50: '#939393',
  gray20: '#D8D8D8',
  gray10: '#F7F8F9',
}
const fonts = {
  feature: "'Exo 2', sans-serif",
  base: "'Lato', sans-serif",
}
export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 1000,
  lg: 1400,
}
const maxWidth = {
  sm: 500,
  md: 900,
  lg: 1600,
}
const outerMargin = '6%'
export const flexboxgrid = {
  // Defaults
  gridSize: 10, // columns
  gutterWidth: 1, // rem
  outerMargin: 0, // rem
  mediaQuery: 'only screen',
  container: {
    sm: toREM(maxWidth.sm), // rem
    md: toREM(maxWidth.md), // rem
    lg: toREM(maxWidth.lg), // rem
    xl: toREM(maxWidth.xl), // rem
  },
  // container: {
  //   sm: 46, // rem
  //   md: 61, // rem
  //   lg: 76, // rem
  // },
  breakpoints: {
    xs: 0, // em
    sm: toREM(breakpoints.sm), // em
    md: toREM(breakpoints.md), // em
    lg: toREM(breakpoints.lg), // em
    xl: toREM(breakpoints.xl), // em
  },
  // breakpoints: {
  //   xs: 0, // em
  //   sm: 48, // em
  //   md: 64, // em
  //   lg: 75, // em
  // },
}

export const theme = {
  colors,
  fonts,
  breakpoints,
  maxWidth,
  flexboxgrid,
  outerMargin,
}
