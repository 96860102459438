import React from 'react'
import { registerLinkResolver } from 'gatsby-source-prismic-graphql'
import { ThemeProvider } from 'styled-components'
import smoothscroll from 'smoothscroll-polyfill'
import 'smoothscroll-anchor-polyfill'

import { previewLinkResolver } from './src/utils/linkResolver'
import { theme } from './src/styles/vars'
import { StateProvider } from './src/context/GlobalStateContext'
import { initialState, reducer } from './src/store/global'
/**
 * We register `previewLinkResolver` here for preview setup
 */
registerLinkResolver(previewLinkResolver)

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <StateProvider initialState={initialState} reducer={reducer}>
      {element}
    </StateProvider>
  </ThemeProvider>
)
// Called when the Gatsby browser runtime first starts.
export const onClientEntry = () => {
  // kick off the polyfill!
  smoothscroll.polyfill()
}

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
}
